import React, { useEffect, useState } from "react";
import "../Screens/NewGallery.css";
import ReactPlayer from "react-player";

function NewGallery() {
  const [imageWidth, setImageWidth] = useState(window.innerWidth / 3);
  const [isMobile, setIsMobile] = useState(true);
  const [selectedImage, setSelectedImage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 4; // Number of images to display per page
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [imageCount, setImageCount] = useState(8);
  const [playVideo, setPlayVideo] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [
    { type: "image", src: `${process.env.PUBLIC_URL}/q.jpeg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/w.jpeg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/e.jpg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/r.jpeg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/t.jpg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/y.jpeg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/e.jpg` },
    { type: "image", src: `${process.env.PUBLIC_URL}/l.png` },
    {
      type: "video",
      src: `${process.env.PUBLIC_URL}/port_vedio1.mp4`,
      thumbnail: `${process.env.PUBLIC_URL}/mob-gallery-car video_e.png`,
      text: "Acting",
    },
    {
      type: "video",
      src: `${process.env.PUBLIC_URL}/about.mp4`,
      thumbnail: `${process.env.PUBLIC_URL}/mob-gallery-about video_e.png`,
      text: "About me",
    },
  ];

  const videoArr = [
    {
      type: "video",
      src: `${process.env.PUBLIC_URL}/port_vedio1.mp4`,
      thumbnail: `${process.env.PUBLIC_URL}/mob-gallery-car video_e.png`,
      text: "Acting",
    },
    {
      type: "video",
      src: `${process.env.PUBLIC_URL}/about.mp4`,
      thumbnail: `${process.env.PUBLIC_URL}/mob-gallery-about video_e.png`,
      text: "About me",
    },
  ];

  const handlePrevClick = () => {
    setSelectedImage((prevSelected) =>
      prevSelected === 0 ? images.length - 1 : prevSelected - 1
    );
  };

  const handleNextClick = () => {
    setSelectedImage((prevSelected) =>
      prevSelected === images.length - 1 ? 0 : prevSelected + 1
    );
  };

  const totalImages = images.length;
  const totalPages = Math.ceil(images.length / imagesPerPage);

  const handlePageClick = (page) => {
    setSelectedImage((page - 1) * imagesPerPage);
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const pageRanges = [];
    for (let i = 1; i <= totalPages; i++) {
      const startRange = (i - 1) * imagesPerPage + 1;
      const endRange =
        i * imagesPerPage > totalImages ? totalImages : i * imagesPerPage;
      const rangeText = `${startRange} - ${endRange}`;

      pageRanges.push(
        <div
          key={i}
          className={`page-number ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {rangeText}
        </div>
      );
    }
    return pageRanges;
  };
  const handleImageClick = (index, type) => {
    console.log("INDEX-----------", index);
    console.log("CURRENT PAGE---------", currentPage);
    if (type == "video") {
      if (index == 0) {
        setSelectedImage(8);
        setPlayVideo(true);
      } else if (index == 1) {
        setSelectedImage(9);
        setPlayVideo(true);
      }
    } else if (currentPage == 1) {
      setSelectedImage(index);
    } else if (currentPage == 2) {
      if (index == 0) {
        setSelectedImage(4);
      } else if (index == 1) {
        setSelectedImage(5);
      } else if (index == 2) {
        setSelectedImage(6);
      } else if (index == 3) {
        setSelectedImage(7);
      }
    }
  };

  const handleExpandClick = () => {
    setIsLightboxOpen(!isLightboxOpen);
  };

  // Close the lightbox when changing the selected image or page
  useEffect(() => {
    setIsLightboxOpen(false);
  }, [selectedImage, currentPage]);

  return (
    <div
      onClick={() => {
        if (isLightboxOpen) {
          setIsLightboxOpen(false);
        }
      }}
    >
      <div className="gallery_bx" id="image">
        {isMobile ? (
          <h4 className="text-center title">GALLERY</h4>
        ) : (
          <h4 className="text-center title">IMAGE GALLERY</h4>
        )}
        <div>
          <div className="image-gallery-box container-fluid">
            {images[selectedImage]?.type === "image" && (
              <div
                className={`nav-arrow navarrow1 ${
                  selectedImage === 0 ? "disabled" : ""
                }`}
                onClick={handlePrevClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="75"
                  height="96"
                  viewBox="0 0 75 96"
                  fill="none"
                  className="nav-arrow-icons"
                >
                  <path
                    d="M0 48L75 0.368607V95.6314L0 48Z"
                    fill="url(#paint0_linear_132_2097)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_132_2097"
                      x1="0"
                      y1="48"
                      x2="100"
                      y2="48"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#BC9B5D" />
                      <stop
                        offset="1"
                        stop-color="#BC9B5D"
                        stop-opacity="0.49"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}

            <div
              className={`main-image-container ${
                isLightboxOpen ? "lightbox-open" : ""
              }`}
            >
              {!isMobile && (
                <div className="expand-icon" onClick={handleExpandClick}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                    className={`expand ${isLightboxOpen ? "hidden" : ""}`}
                    onClick={handleExpandClick}
                  >
                    <path
                      d="M30.7991 3H48.5107C48.7818 3 49.0001 3.21826 49.0001 3.48936V21.201"
                      stroke="white"
                      stroke-width="5"
                    />
                    <path
                      d="M3 21.201V3.48936C3 3.21826 3.21826 3 3.48936 3H21.201"
                      stroke="#BC9B5D"
                      stroke-width="5"
                    />
                    <path
                      d="M21.201 49H3.48936C3.21826 49 3 48.7817 3 48.5106V30.799"
                      stroke="white"
                      stroke-width="5"
                    />
                    <path
                      d="M49.0001 30.799V48.5106C49.0001 48.7817 48.7818 49 48.5107 49H30.7991"
                      stroke="#BC9B5D"
                      stroke-width="5"
                    />
                  </svg>
                  {/* <p className="overlay-svg overly-text">Expand</p> */}
                  <p className={`expand ${isLightboxOpen ? "hidden" : ""}`}>
                    Expand
                  </p>
                </div>
              )}
              {isLightboxOpen && (
                <div className="close-lightbox" onClick={handleExpandClick}>
                  <i className="fa fa-remove" style={{ color: "white" }}></i>
                </div>
              )}

              {!isMobile ? (
                <>
                  {isLightboxOpen && (
                    <div className="close-lightbox" onClick={handleExpandClick}>
                      <i
                        className="fa fa-remove"
                        style={{ color: "white" }}
                      ></i>
                    </div>
                  )}
                  {images[selectedImage]?.type == "image" && (
                    <img
                      src={images[selectedImage]?.src}
                      alt={`Image ${selectedImage + 1}`}
                      // className="overlay-image"
                      className={`overlay-image ${
                        isLightboxOpen ? "expanded" : ""
                      }`}
                    />
                  )}
                </>
              ) : (
                <>
                  {images[selectedImage]?.type == "image" ? (
                    <img
                      src={images[selectedImage]?.src}
                      alt={`Image ${selectedImage + 1}`}
                      // className="overlay-image"
                      className={`overlay-image ${
                        isLightboxOpen ? "expanded" : ""
                      }`}
                    />
                  ) : (
                    isMobile && (
                      <ReactPlayer
                        url={images[selectedImage]?.src}
                        playing={playVideo}
                        loop={false}
                        volume={1}
                        controls={true}
                        // width="960px"
                        // height="452px"
                        playIcon={false}
                        width="375px"
                        height="auto"
                        className="player"
                      />
                    )
                  )}
                </>
              )}
            </div>

            {images[selectedImage]?.type === "image" && (
              <div
                className={`nav-arrow navarrow2 ${
                  selectedImage === imageCount - 1 ? "disabled" : ""
                }`}
                onClick={handleNextClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="75"
                  height="96"
                  viewBox="0 0 75 96"
                  fill="none"
                  className="nav-arrow-icons"
                >
                  <path
                    d="M75 48L0 95.6314V0.368603L75 48Z"
                    fill="url(#paint0_linear_132_2096)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_132_2096"
                      x1="75"
                      y1="48"
                      x2="-25"
                      y2="48"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#BC9B5D" />
                      <stop
                        offset="1"
                        stop-color="#BC9B5D"
                        stop-opacity="0.49"
                      />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}
          </div>
          {!isMobile && (
            <div
              // className="image-list-container image-list-container-web image-list-tab"
              className="image-grid"
              style={{ top: "3px", position: "relative" }}
            >
              {images.map(
                (image, index) =>
                  image?.type == "image" && (
                    <img
                      key={index}
                      src={image?.src}
                      alt={`Image ${index + 1}`}
                      className={index === selectedImage ? "selected" : ""}
                      onClick={() => handleImageClick(index)}
                    />
                  )
              )}
            </div>
          )}
        </div>
        {isMobile ? (
          <>
            {/* <div className="image-list-container image-list-container-mob image-list-web"> */}
            <div className="listing-small-image-box">
              {console.log("current page----------", currentPage)}
              {selectedImage < 8
                ? images
                    .slice(
                      (currentPage - 1) * imagesPerPage,
                      currentPage * imagesPerPage
                    )
                    .map((image, index) =>
                      image?.type == "image" && selectedImage !== 8 ? (
                        <div>
                          {console.log(
                            "IMAGE TYPE---------",
                            image?.type,
                            "src : ",
                            image?.src
                          )}
                          <img
                            key={index}
                            src={image?.src}
                            alt={`Image ${index + 1}`}
                            className={
                              index === selectedImage ? "selected" : ""
                            }
                            onClick={() => {
                              handleImageClick(index);
                            }}
                          />
                        </div>
                      ) : selectedImage == 8 ? (
                        <></>
                      ) : (
                        <></>
                      )
                    )
                : videoArr.map((video, index) => (
                    <div
                      className="m-3"
                      onClick={() => handleImageClick(index, video?.type)}
                    >
                      {console.log("IMAGE TYPE---------", video?.type)}
                      <img
                        src={video?.thumbnail}
                        alt="Thumbnail"
                        className="w-100"
                      />
                      <p className="text-center video-gallery-text">
                        {video?.text}
                      </p>
                    </div>
                  ))}
            </div>

            <div
              className={`container controls2 ${
                images[selectedImage]?.type == "video" ? "video-control2" : ""
              }`}
            >
              <div className="pagination-container2 mt-0 ">
                <div
                  className={`nav-arrow2 ${
                    selectedImage === 0 ? "disabled" : ""
                  }`}
                  // className=""
                  onClick={handlePrevClick}
                  style={{ left: "-10px", position: "relative" }}
                >
                  {"<"} Back
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="pagination-count2"
                >
                  {renderPagination()}
                </div>

                <div
                  // className="nav-arrow3"
                  className={`nav-arrow3 ${
                    selectedImage === 8 ? "disabled" : ""
                  }`}
                  onClick={handleNextClick}
                  style={{ left: "10px", position: "relative" }}
                >
                  Next {">"}
                </div>
              </div>
            </div>
            <div
              className={`actor-name vedio-lists container controls2 ${
                images[selectedImage]?.type == "video" ? "video-control2" : ""
              }`}
            >
              <div>
                <h2>Marten</h2>
                <h4>Eckerstrom</h4>
                <h5>Actor</h5>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="52"
                  viewBox="0 0 52 52"
                  fill="none"
                  className={`expand ${isLightboxOpen ? "hidden" : ""}`}
                  onClick={handleExpandClick}
                >
                  <path
                    d="M30.7991 3H48.5107C48.7818 3 49.0001 3.21826 49.0001 3.48936V21.201"
                    stroke="white"
                    stroke-width="5"
                  />
                  <path
                    d="M3 21.201V3.48936C3 3.21826 3.21826 3 3.48936 3H21.201"
                    stroke="#BC9B5D"
                    stroke-width="5"
                  />
                  <path
                    d="M21.201 49H3.48936C3.21826 49 3 48.7817 3 48.5106V30.799"
                    stroke="white"
                    stroke-width="5"
                  />
                  <path
                    d="M49.0001 30.799V48.5106C49.0001 48.7817 48.7818 49 48.5107 49H30.7991"
                    stroke="#BC9B5D"
                    stroke-width="5"
                  />
                </svg>
                <p className="expand-small-text">Expand</p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default NewGallery;
