import React from "react";

function TabletFooter() {
  return (
    <div>
      <div className="text-white p-5" style={{ backgroundColor: "#BC9B5D" }}>
        <div
          className="footer-mob"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <h4
              style={{
                color: "#1B1B19",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "14px",
                letterSpacing: "0.75px",
              }}
            >
              MANAGEMENT
            </h4>
            <h6 style={{ color: "#1B1B19" }}>Maria Vascsak</h6>
            <a
              style={{
                textDecoration: "none",
                top: "-9px",
                position: "relative",
                color: "#1B1B19",
              }}
              href={`mailto:${"maria@imacontent.se"}`}
            >
              maria@imacontent.se
            </a>
          </div>
          <div>
            <div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    top: "22px",
                    left: "15px",
                    position: "relative",
                  }}
                >
                  <div className="m-1">
                    <a href="https://www.imdb.com/name/nm5103942/?ref_=nv_sr_srsg_0_tt_0_nm_2_q_m%25C3%25A5rten%2520eckestr%25C3%25B6m">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="19"
                        viewBox="0 0 45 19"
                        fill="none"
                      >
                        <path
                          d="M10.2854 18.2204H6.26758V0.201172H12.3747L13.4997 7.76281H13.6604L14.464 0.201172H20.5712V18.2204H16.5533V7.44103H16.3926L14.9462 18.2204H11.8926L10.2854 7.44103V18.2204Z"
                          fill="#1B1B19"
                        />
                        <path
                          d="M22.1787 0.0402219C22.8216 0.0402219 27.3216 -0.120665 29.7323 0.201107C31.6609 0.361993 32.6251 1.97085 32.7859 3.90148C32.9466 7.44097 32.9466 10.9805 32.9466 14.5199C32.9466 14.8417 32.9466 15.3244 32.7859 15.807C32.4644 17.255 31.6609 18.0594 29.7323 18.2203C27.3216 18.3812 24.9109 18.3812 22.6609 18.3812C22.6609 18.3812 22.5001 18.3812 22.3394 18.3812V0.0402219H22.1787ZM27.0001 3.41882V15.0026C27.8037 15.0026 28.2859 14.6808 28.2859 13.8764C28.2859 10.8196 28.2859 7.60185 28.2859 4.38414C28.2859 3.57971 27.9644 3.25794 27.0001 3.41882Z"
                          fill="#1B1B19"
                        />
                        <path
                          d="M0 18.2204V0.201172H4.66072V18.2204H0Z"
                          fill="#1B1B19"
                        />
                        <path
                          d="M44.839 6.95838C44.6783 5.67129 43.8747 5.02775 42.589 4.70597C41.3033 4.54509 40.0176 4.70597 38.8926 5.83217V0.201172H34.3926V18.2204H38.5712C38.7319 17.8986 38.7319 17.5768 38.8926 17.416C38.8926 17.416 38.8926 17.416 39.0533 17.416C39.214 17.5768 39.3747 17.7377 39.5354 17.8986C40.6604 18.703 41.9462 18.8639 43.2319 18.3813C44.3569 17.8986 44.839 16.9333 44.839 15.8071C44.839 14.52 44.9997 13.072 44.9997 11.6241C44.9997 10.0152 44.9997 8.40635 44.839 6.95838ZM40.339 15.0027C40.339 15.3244 40.0176 15.6462 39.6962 15.6462C39.3747 15.6462 39.0533 15.3244 39.0533 15.0027V8.08458C39.0533 7.76281 39.3747 7.44103 39.6962 7.44103C40.0176 7.44103 40.339 7.76281 40.339 8.08458V15.0027Z"
                          fill="#1B1B19"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="m-1">
                    <a href="https://www.instagram.com/marteneckerstrom?igsh=MXJkem5obWFsdml4Mg==">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_836_131)">
                          <path
                            d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"
                            fill="#1B1B19"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_836_131">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center website-link">
          <a
            href="https://www.marteneckerstrom.se"
            style={{ fontSize: "20px", color: "#1B1B19" }}
          >
            www.marteneckerstrom.se
          </a>
        </div>
      </div>
    </div>
  );
}

export default TabletFooter;
