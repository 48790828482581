import React, { useRef } from "react";
import HomeScreen from "./Screens/HomeScreen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GalleryScreen from "./Screens/GalleryScreen";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/gallery" element={<GalleryScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
