import React from "react";
import { INSTAGRAM_IMAGE } from "../../Contants/Images/urls";

function FooterMob() {
  return (
    <div className="text-white p-5" style={{ backgroundColor: "#BC9B5D" }}>
      <div className="footer-mob">
        <div>
          <h4>MANAGEMENT</h4>
          <h6>Maria Vascsak</h6>
          <a
            style={{
              textDecoration: "none",
              top: "-9px",
              position: "relative",
            }}
            href={`mailto:${"maria@imacontent.se"}`}
          >
            maria@imacontent.se
          </a>
        </div>
        <div>
          <div>
            <div>
              <div style={{ display: "flex", justifyContent: "space-around" ,top:'22px',left:'15px',position:'relative'}}>
                <div className="m-1">
                  <a href="https://www.imdb.com/name/nm5103942/?ref_=nv_sr_srsg_0_tt_0_nm_2_q_m%25C3%25A5rten%2520eckestr%25C3%25B6m">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="99"
                      height="42"
                      viewBox="0 0 99 42"
                      fill="none"
                      className="mobi"
                      style={{ width: "45px", height: "18px" }}
                    >
                      <path
                        d="M22.5566 40.8863H13.7454V1.41211H27.1384L29.6056 17.9772H29.958L31.7203 1.41211H45.1133V40.8863H36.3021V17.2723H35.9496L32.7776 40.8863H26.0811L22.5566 17.2723V40.8863Z"
                        fill="black"
                      />
                      <path
                        d="M48.6378 1.06028C50.0475 1.06028 59.9161 0.707831 65.2028 1.41273C69.4322 1.76517 71.5469 5.28965 71.8993 9.51903C72.2518 17.2729 72.2518 25.0267 72.2518 32.7806C72.2518 33.4855 72.2518 34.5428 71.8993 35.6002C71.1944 38.7722 69.4322 40.5345 65.2028 40.8869C59.9161 41.2393 54.6294 41.2393 49.6951 41.2393C49.6951 41.2393 49.3427 41.2393 48.9902 41.2393V1.06028H48.6378ZM59.2112 8.46169V33.8379C60.9734 33.8379 62.0308 33.133 62.0308 31.3708C62.0308 24.6743 62.0308 17.6253 62.0308 10.5764C62.0308 8.81413 61.3259 8.10924 59.2112 8.46169Z"
                        fill="black"
                      />
                      <path
                        d="M0 40.8863V1.41211H10.221V40.8863H0Z"
                        fill="black"
                      />
                      <path
                        d="M98.3329 16.2149C97.9805 13.3953 96.2183 11.9855 93.3987 11.2807C90.5791 10.9282 87.7595 11.2807 85.2924 13.7478V1.41211H75.4238V40.8863H84.5875C84.9399 40.1814 84.9399 39.4765 85.2924 39.124C85.2924 39.124 85.2924 39.124 85.6448 39.124C85.9973 39.4765 86.3497 39.8289 86.7022 40.1814C89.1693 41.9436 91.9889 42.2961 94.8085 41.2387C97.2756 40.1814 98.3329 38.0667 98.3329 35.5996C98.3329 32.78 98.6854 29.6079 98.6854 26.4359C98.6854 22.9114 98.6854 19.387 98.3329 16.2149ZM88.4644 33.8373C88.4644 34.5422 87.7595 35.2471 87.0546 35.2471C86.3497 35.2471 85.6448 34.5422 85.6448 33.8373V18.6821C85.6448 17.9772 86.3497 17.2723 87.0546 17.2723C87.7595 17.2723 88.4644 17.9772 88.4644 18.6821V33.8373Z"
                        fill="black"
                      />
                    </svg> */}
                    {/* <img
                      src={process.env.PUBLIC_URL + "imdb.png"}
                      className="imdb-icon"
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="45"
                      height="19"
                      viewBox="0 0 45 19"
                      fill="none"
                    >
                      <path
                        d="M10.2854 18.2204H6.26758V0.201172H12.3747L13.4997 7.76281H13.6604L14.464 0.201172H20.5712V18.2204H16.5533V7.44103H16.3926L14.9462 18.2204H11.8926L10.2854 7.44103V18.2204Z"
                        fill="#1B1B19"
                      />
                      <path
                        d="M22.1787 0.0402219C22.8216 0.0402219 27.3216 -0.120665 29.7323 0.201107C31.6609 0.361993 32.6251 1.97085 32.7859 3.90148C32.9466 7.44097 32.9466 10.9805 32.9466 14.5199C32.9466 14.8417 32.9466 15.3244 32.7859 15.807C32.4644 17.255 31.6609 18.0594 29.7323 18.2203C27.3216 18.3812 24.9109 18.3812 22.6609 18.3812C22.6609 18.3812 22.5001 18.3812 22.3394 18.3812V0.0402219H22.1787ZM27.0001 3.41882V15.0026C27.8037 15.0026 28.2859 14.6808 28.2859 13.8764C28.2859 10.8196 28.2859 7.60185 28.2859 4.38414C28.2859 3.57971 27.9644 3.25794 27.0001 3.41882Z"
                        fill="#1B1B19"
                      />
                      <path
                        d="M0 18.2204V0.201172H4.66072V18.2204H0Z"
                        fill="#1B1B19"
                      />
                      <path
                        d="M44.839 6.95838C44.6783 5.67129 43.8747 5.02775 42.589 4.70597C41.3033 4.54509 40.0176 4.70597 38.8926 5.83217V0.201172H34.3926V18.2204H38.5712C38.7319 17.8986 38.7319 17.5768 38.8926 17.416C38.8926 17.416 38.8926 17.416 39.0533 17.416C39.214 17.5768 39.3747 17.7377 39.5354 17.8986C40.6604 18.703 41.9462 18.8639 43.2319 18.3813C44.3569 17.8986 44.839 16.9333 44.839 15.8071C44.839 14.52 44.9997 13.072 44.9997 11.6241C44.9997 10.0152 44.9997 8.40635 44.839 6.95838ZM40.339 15.0027C40.339 15.3244 40.0176 15.6462 39.6962 15.6462C39.3747 15.6462 39.0533 15.3244 39.0533 15.0027V8.08458C39.0533 7.76281 39.3747 7.44103 39.6962 7.44103C40.0176 7.44103 40.339 7.76281 40.339 8.08458V15.0027Z"
                        fill="#1B1B19"
                      />
                    </svg>
                  </a>
                </div>
                <div className="m-1">
                  <a href="https://www.instagram.com/marteneckerstrom?igsh=MXJkem5obWFsdml4Mg==">
                    {/* <img src={INSTAGRAM_IMAGE} className="instagram-icon" /> */}
                    {/* <img
                      src={process.env.PUBLIC_URL + "instagram.png"}
                      className="instagram-icon"
                      style={{ top: "5px", position: "relative" }}
                    /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_836_131)">
                        <path
                          d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0ZM12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16ZM18.406 4.155C17.61 4.155 16.965 4.8 16.965 5.595C16.965 6.39 17.61 7.035 18.406 7.035C19.201 7.035 19.845 6.39 19.845 5.595C19.845 4.8 19.201 4.155 18.406 4.155Z"
                          fill="#1B1B19"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_836_131">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                {/* <div className="m-1">
                  <a href="https://se.linkedin.com/in/marten-eckerstrom-311a101b2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_836_133)">
                        <path
                          d="M19 0H5C2.239 0 0 2.239 0 5V19C0 21.761 2.239 24 5 24H19C21.762 24 24 21.761 24 19V5C24 2.239 21.762 0 19 0ZM8 19H5V8H8V19ZM6.5 6.732C5.534 6.732 4.75 5.942 4.75 4.968C4.75 3.994 5.534 3.204 6.5 3.204C7.466 3.204 8.25 3.994 8.25 4.968C8.25 5.942 7.467 6.732 6.5 6.732ZM20 19H17V13.396C17 10.028 13 10.283 13 13.396V19H10V8H13V9.765C14.396 7.179 20 6.988 20 12.241V19Z"
                          fill="#1B1B19"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_836_133">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center website-link">
        <a href="https://www.marteneckerstrom.se">www.marteneckerstrom.se</a>
      </div>
    </div>
  );
}

export default FooterMob;
